/** @jsx jsx */
import { jsx, Heading } from 'theme-ui'
import Layout from '../components/layout'

export default function PageLayout({ pageContext, children, ...props }) {

  const { title, subTitle, seo } = pageContext.frontmatter

  return (
    <Layout {...seo}>
      <section>
        <div sx={{ mb: 5 }}>
          <Heading as="h1" variant="pageTitle" sx={{ textAlign: 'center' }}>{title}</Heading>
          {subTitle && <div sx={{ fontFamily:'caviar dreams, sans-serif', textAlign: 'center', fontSize: 5 }}>{subTitle}</div>}
        </div>

        <div sx={{ mx: [0, 6] }}>{children}</div>
      </section>
    </Layout>
  )
}
