import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/collectif-swing/collectif-swing/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Constat`}</h1>
    <p>{`Le lancement de nos activités respectives nous a amené au constat que les femmes qui entreprennent rencontrent des difficultés particulières, propres à leur place dans la société et liées aux implications dans leur vie familiale.
Sur Monnières ce sont des petites structures, où les femmes exercent parfois seules. Nous avons également pu noter que des opportunités n’ont pu être saisies par manque de connaissance de nos offres commerciales. D’où le besoin de nous structurer pour que nos compétences soient mieux identifiées sur notre territoire.`}</p>
    <h1>{`Objectifs`}</h1>
    <ul>
      <li parentName="ul">{`Savoir identifier les spécifités de l’entreprenariat au féminin et les freins éventuels,`}</li>
      <li parentName="ul">{`Accompagner, encourager et soutenir le développement des entreprises créées ou reprises par des femmes,`}</li>
      <li parentName="ul">{`Inciter les entrepreneures à sortir de l’isolement,`}</li>
      <li parentName="ul">{`Promouvoir l’entrepreneuriat féminin,`}</li>
      <li parentName="ul">{`Donner de la visibilité à nos compétences et à nos activités,`}</li>
      <li parentName="ul">{`Mieux connaître les offres des unes et des autres, les champs de compétences,`}</li>
      <li parentName="ul">{`Développer des courants d’affaires entre les entrepreneuses adhérentes,`}</li>
      <li parentName="ul">{`Favoriser le travail en réseau et les échanges,`}</li>
      <li parentName="ul">{`Faire connaître les oppotunités,`}</li>
      <li parentName="ul">{`Valoriser nos métiers,`}</li>
      <li parentName="ul">{`S’informer / se former,`}</li>
      <li parentName="ul">{`Privilégier et développer les conditions d’une économie durable,`}</li>
      <li parentName="ul">{`Regrouper les compétences pour répondre à des appels d’offres, des appels à candidature...`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      